import react, {useState,useEffect,useRef} from "react";
import "./ProjectScreen.css"
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";
import { ReactComponent as Icon1 } from "../images/tree.svg"
import { ReactComponent as Icon2 } from "../images/play-icon.svg"

export default function ProjectScreen() {
  const {ref, inView} = useInView({threshold: 0.5});
  const [view, setView] = useState();

  useEffect(() => {
    setView(inView)
  },[inView])

  const animation1 = {
    animate: {
      opacity: 1,
      y: 0,
      x:0,
      transition: {
   
        bounce: 0,
        duration: 1
      }
    }
  };
  

  const animation2 = {
    animate: {
      opacity: 0,
      y: 25,
      transition: {
 
        bounce: 0,
        duration: 1
      }
    }
  };

  const animation3 = {
    animate: {
      opacity: 1,
      y: 0,
      transition: {

        delay:1,
        bounce: 0,
        duration: 3
      }
    }
  };

  const animation4 = {
    animate: {
      opacity: 1,
      y: 0,
      transition: {

        delay:1.5,
        bounce: 0,
        duration: 3
      }
    }
  };

  const animation5 = {
    animate: {
      x: 90,
      transition: {

        bounce: 0,
        duration: 1
      }
    }
  };

  const animation6 = {
    animate: {
      opacity:1,
      transition: {

        delay: 0.5,
        bounce: 0,
        duration: 2
      }
    }
  };

  const animation7 = {
    animate: {
      opacity:0,
      transition: {

        bounce: 0,
        duration: 1
      }
    }
  };
  const Componet = () => {
    const [project, setProject] = useState(null);
    const [selected, setSelected] = useState(false);
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const timer = useRef(null);

   useEffect(() => {
    switch(project) {
      case 1:
        clearInterval(timer.current);
        setSelected(true)
        setTitle("Personal Page");
        setText("Osobní portfolio, projekt je zaměřený spíše na design. Použitá technologie React.");
      break
      case 2:
        clearInterval(timer.current);
        setSelected(true)
        setTitle("Brain Chess");
        setText("Maturitní projekt, webová aplikace pro hru Šachu, kde si uživatel volí startovní pozici. Použitá technologie React, Firebase.");
      break
      default:
        setSelected(false);
        timer.current = setTimeout(() => {
          setTitle("");
          setText("");
        },1000)
    }
    return(() =>
      clearInterval(timer.current)
    )
   },[project])
    return (
      <div className="projectScreen__component">
        <h1 className="projectScreen__h1">PROJEKTY</h1>
          <motion.div initial={{opacity: 0}} {...view? animation6 : animation2}  className="projectScreen__component-box"> 
            <Box onClick={() => setProject(1)} unClick={() => setProject(null)} selected={project ===1} icon={<Icon1 className="projectScreen__icon-big"/>} title={"Personal Page"} style={{marginBottom: 30}}/>
            <Box onClick={() => {project === 2? window.location.replace('https://brain-chess.web.app/') : setProject(2)}} unClick={() => setProject(null)} selected={project ===2} icon={<Icon2 className="projectScreen__icon"/>} title={"Brain Chess"}/>
          </motion.div> 
          <TextBox title={title} text={text} selected={selected}/>
      </div>
    )
  } 


  const Box = ({onClick, unClick, selected, style, icon, title}) => {
    return (
      <motion.div initial={{opacity: 0, y:25}} {...view? animation3 : animation2} onHoverStart={onClick}  onHoverEnd={unClick} onClick={onClick} style={style} className="projectScreen__box">
        <motion.div {...selected ? animation5 : animation1} className="projectScreen__box-flex">
          {icon}
          <motion.h2 {...selected ? animation7 : animation1} className="projectScreen__h2">{title}</motion.h2>
        </motion.div>
        <motion.div style={{justifyContent:"center"}} {...selected ? animation5 : animation1} className="projectScreen__box-flex">
          <h2 className="projectScreen__h2-box">{icon}</h2>
        </motion.div>
      </motion.div>
    )
  } 

  const TextBox = ({title,text,selected}) => {
    return (
      <motion.div initial={{opacity: 0, y:25}} {...view? animation4 : animation2} className="projectScreen__box-text">
        <motion.div initial={{opacity: 0, y:25}} {...selected ? animation1 : animation2}>
          <h2 className="projectScreen__h2-text">{title}</h2>
          <p className="projectScreen__p">{text}</p>
        </motion.div>
      </motion.div>
    )
  } 

  return (
    <div initial={{opacity: 0}} {...view ? animation1 : animation2} className="projectScreen__container">
      <motion.div ref={ref} className="projectScreen__header" >
        <motion.div initial={{opacity:0, y:25}} {...view ? animation1 : animation2} className="projectScreen__values">
          <Componet/>
        </motion.div>
      </motion.div>
    </div>
  )
}
import react, {useState,useEffect, useRef} from "react";
import "./SkillsScreen.css"
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";
import { ReactComponent as Icon1 } from "../images/html-icon.svg"
import { ReactComponent as Icon2 } from "../images/css-icon.svg"
import { ReactComponent as Icon3 } from "../images/js-icon.svg"
import { ReactComponent as Icon4 } from "../images/java-icon.svg"
import { ReactComponent as Icon5 } from "../images/mysql-icon.svg"
import { ReactComponent as Icon6 } from "../images/react-icon.svg"
import { ReactComponent as Icon7 } from "../images/firebase-icon.svg"
import { ReactComponent as Icon8 } from "../images/angular-icon.svg"
import { ReactComponent as Icon9 } from "../images/webpack-icon.svg"
import { ReactComponent as Icon10 } from "../images/nodejs-icon.svg"

export default function SkillsScreen() {
  const {ref, inView} = useInView({threshold: 0.5});
  const [view, setView] = useState();

  useEffect(() => {
    setView(inView)
  },[inView])

  const animation1 = {
    animate: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const animation2 = {
    animate: {
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const animation3 = {
    animate: {
      opacity: 1,
      y:0,
      height: "auto",
      transition: {
        type: "spring",
        bounce: 0,
        delay:0.5,
        duration: 3
      }
    }
  };

  const animation4 = {
    animate: {
      opacity: 0,
      y: 110,
      height: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 3
      }
    }
  };

  const animation5 = {
    animate: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0,
        delay:2,
        duration: 5
      }
    }
  };

  const animation6 = {
    animate: {
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const animation7 = {
    animate: {
      width: 180,
      x:0,
      transition: {
        type: "spring",
        bounce: 0,
        delay:2,
        duration: 3
      }
    }
  };

  const animation8 = {
    animate: {
      marginLeft: 5,
      width: 30,
      transition: {
        type: "spring",
        bounce: 0,
        delay: 3,
        duration: 3
      }
    }
  };

  const animation9 = {
    animate: {
      scale: 1.3,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const Componet = () => {
    return (
      <div className="skillsScreen__component">
        <h1 className="skillsScreen__h1">&#8205; DOVEDNOSTI &#8205;</h1>
        <div className="skillsScreen__component-box"> 
          <Leangues/>
          <Other/>
        </div> 
        
      </div>
    )
  } 

  const Leangues = () => {
    return (
      <motion.div initial={{opacity:0, height:10, y:110}} {...view ? animation3 : animation4} className="skillsScreen__component-box1"> 
        <Box value={<Icon1 className="skillsScreen__icon"/>} text={"Html"} skill={5} first={true}/>
        <Box value={<Icon2 className="skillsScreen__icon"/>} text={"Css"}  skill={5}/>
        <Box value={<Icon3 style={{width:24, height:24, marginLeft:3, marginRight:14}}  className="skillsScreen__icon"/>} text={"Js"} skill={3}/>
        <Box value={<Icon4 style={{width:24, height:24 ,marginLeft:3, marginRight:12, borderRadius:5}} className="skillsScreen__icon"/>} text={"Java"} skill={3}/>
        <Box value={<Icon5 style={{width:24, height:24, marginLeft:3, fill:"white"}} className="skillsScreen__icon"/>} text={"Sql"} skill={2}/>
      </motion.div> 
    )
  } 

  const Other = () => {
    const [selected, setSelected] = useState(false);
    return (
      <motion.div initial={{opacity:0}} {...view ? animation5 : animation6}> 
        <div className="skillsScreen__component-flex">
          <motion.div onTapStart={() => {setSelected(1); setTimeout(() => setSelected(false),1500)}} onHoverStart={() => setSelected(1)} onHoverEnd={() => setSelected(false)} >
            <SmallBox value={ <Icon6 className="skillsScreen__icon-big"/>} text={"React"} show={selected === 1} visible={selected === false || selected === 1} />
          </motion.div>
          <motion.div onTapStart={() => {setSelected(2); setTimeout(() => setSelected(false),1500)}} onHoverStart={() => setSelected(2)} onHoverEnd={() => setSelected(false)} >
            <SmallBox value={ <Icon7 className="skillsScreen__icon-big"/>} text={"Firebase"} show={selected === 2} visible={selected === false || selected === 2} />
          </motion.div>
          <motion.div onTapStart={() => {setSelected(3); setTimeout(() => setSelected(false),1500)}} onHoverStart={() => setSelected(3)} onHoverEnd={() => setSelected(false)} >
            <SmallBox value={ <Icon8 className="skillsScreen__icon-big"/>} text={"Angular"} show={selected === 3} visible={selected === false || selected === 3} />
          </motion.div>

        </div>
        <div className="skillsScreen__component-flex">
          <motion.div onTapStart={() => {setSelected(4); setTimeout(() => setSelected(false),1500)}} onHoverStart={() => setSelected(4)} onHoverEnd={() => setSelected(false)} >
              <SmallBox value={ <Icon9 className="skillsScreen__icon-big"/>} text={"Webpack"} show={selected === 4} visible={selected === false || selected === 4} />
            </motion.div>
            <motion.div onTapStart={() => {setSelected(5); setTimeout(() => setSelected(false),1500)}} onHoverStart={() => setSelected(5)} onHoverEnd={() => setSelected(false)} >
              <SmallBox value={ <Icon10 className="skillsScreen__icon-big"/>} text={"Node Js"} show={selected === 5} visible={selected === false || selected === 5} />
            </motion.div>
        </div>
      </motion.div> 
    )
  } 

  const Box = ({value , text, skill , first}) => {
    const [selected, setSelected] = useState(false);
    let array = [];
    for(let x = 0 ; x !== skill; x++) {
      array.push(<motion.div key={x} initial={{marginLeft:0, width:35}} {...view ? animation8: null} className="skillsScreen__component-graf"></motion.div>)
    }
    return (
      <motion.div style={{justifyContent: "space-between"}} onTapStart={() => {setSelected(true); setTimeout(() => setSelected(false),1000)}} onHoverStart={() => setSelected(true)} onHoverEnd={() => setSelected(false)} className="skillsScreen__component-box-flex">
        <div className="skillsScreen__component-box-flex">
        <motion.div {...selected? animation9 : null}>{value}</motion.div>
        <p className="skillsScreen__p">{text}</p>
        </div>
        <motion.div initial={{width:0, x:-180}} {...view ? animation7:null} className="skillsScreen__component-grafbox">{first && <motion.p {...animation5} initial={{opacity:0}} className="skillsScreen__component-box-p1">Neznalý</motion.p>}{array}{first && <motion.p {...animation5} initial={{opacity:0}} className="skillsScreen__component-box-p2">Expert</motion.p>}</motion.div>
      </motion.div>
    )
  } 

  const SmallBox = ({value , text, show, visible}) => {
    return (
     <motion.div {...visible ? animation1 : animation2} className="skillsScreen__component-box-big">
       <motion.div {...show ? animation9 : null}>{value}</motion.div>
       <motion.p {...show ? animation1 : animation2}  className="skillsScreen__component-box-big-p">{text}</motion.p>
     </motion.div>
    )
  } 

  return (
    <div className="skillsScreen__container noselect">
      <motion.div ref={ref} className="skillsScreen__header" >
        <motion.div initial={{opacity:0}} {...view ? animation1 : animation2} className="skillsScreen__values">
          <Componet/>
        </motion.div>
      </motion.div>
    </div>
  )
}
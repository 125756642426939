import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';


const Component = React.lazy(() => import('./App.jsx'));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>loading...</div>}>
      <Component />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

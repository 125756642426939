import react, {useRef, useState, useEffect} from "react";
import { motion } from "framer-motion";
import { ReactComponent as Tree } from "../images/tree.svg"
import { ReactComponent as TopLeft } from "../images/TopLeft-icon.svg"
import { ReactComponent as TopRight } from "../images/TopRight-icon.svg"
import { useInView } from 'react-intersection-observer';
import "./Navbar.css";


const Navbar = ({element}) => {
  const [isAnimating, setIsAnimating] = useState(true)
  const [inScreen, setInScreen] = useState(true)
  const [reload, setReload] = useState(false)
  const {ref, inView} = useInView();

  const animation0 = {
    animate: {
      opacity: [0, 0, 1],
      transition: {
        type: "spring",
        times: [0, 0.99, 1],
        bounce: 0,
        duration: 5
      }
    }
  };

  const animation1 = {
    animate: {
      y: [187.5, 187.5, 0],
      opacity: [0, 1, 1],
      scale: [2.3, 2.3 ,1],
      transition: {
        type: "spring",
        times: [0, 0, 1],
        bounce: 0,
        duration: 2
      }
    }
  };

  const animation2 = {
    animate: {
      y: [0, 187.5, 187.5],
      opacity: [1, 1, 0],
      scale: [1, 2.3 ,2.3],
      transition: {
        type: "spring",
        times: [0, 0.5, 1],
        bounce: 0,
        duration: 4
      }
    }
  };

  const animation3 = {
    animate:{
      opacity: 1,
      scale: 1,
    },
    transition: {
      type: "spring",
      bounce: 0,
      delay:2,
      duration: 6
    }
  };
  const animation4 = {
    animate:{
      opacity: 0,
      scale: 2,
    },
    transition: {
      type: "spring",
      bounce: 0,
      duration: 6
  }
  };

  const animationTopLeft = {
    animate: {
      rotate: [5, 0, 5],
      transformOrigin: 'left top',
      transition: {
        type: "spring",
        times: [0, 0.5, 1],
        bounce: 0,
        repeat: Infinity,
        duration: 4
      }
    }
  };

  const animationTopRight = {
    animate: {
      rotate: [-5, 0, -5],
      transformOrigin: 'right top',
      transition: {
        type: "spring",
        times: [0, 0.5, 1],
        bounce: 0,
        repeat: Infinity,
        duration: 4
      }
    }
  };

  const animationBottomRight = {
    animate: {
      x:[195, 195, 195],
      y:[137, 137, 137],
      rotate: [185, 180, 185],
      transformOrigin: 'top left',
      transition: {
        type: "spring",
        times: [0, 0.5, 1],
        bounce: 0,
        repeat: Infinity,
        duration: 4
      }
    }
  };

  const animationBottomLeft = {
    animate: {
      x:[-195, -195, -195],
      y:[140, 140, 140],
      rotate: [175, 180, 175],
      transformOrigin: 'top right',
      transition: {
        type: "spring",
        times: [0, 0.5, 1],
        bounce: 0,
        repeat: Infinity,
        duration: 4
      }
    }
  };

  const scrollToTop = () => {
    if(inScreen) {
      return;
    }
    setTimeout(() => {
      window.location.reload();
    }, 500)
    setReload(true);
  };

  useEffect(() => {
    if(!isAnimating) {
      setInScreen(inView);
    }

  },[inView, isAnimating])

  useEffect(() => {
    console.log(inScreen)
  },[inScreen])

  const TreeBorder = () => {
    return(
      <div>
        <motion.div initial={{opacity:0 , scale: 2}}  {...!inScreen ? animation3 : animation4}>
          <motion.div {...!inScreen ? animationTopLeft : null} className="navbar__topLeft">
            <TopLeft className="navbar__topLeft-icon"/>
          </motion.div>
          <motion.div {...!inScreen ? animationTopRight : null} className="navbar__topRight">
            <TopRight className="navbar__topRight-icon"/>
          </motion.div>
        </motion.div>
        <motion.div initial={{opacity:0 , scale: 2, y:"100vh"}}  {...!inScreen ? animation3 : animation4}>
          <motion.div {...!inScreen ? animationBottomLeft : null} className="navbar__bottomLeft">
            <TopRight className="navbar__bottomLeft-icon"/>
          </motion.div>
          <motion.div {...!inScreen ? animationBottomRight : null} className="navbar__bottomRight">
            <TopLeft className="navbar__bottomRight-icon"/>
          </motion.div>
        </motion.div>
      </div>
    )
  }

  return( 
    <div className="navbar__body">
      <div className="ref" ref={ref}></div>
      <motion.div {...!reload && animation0} className="navbar__container">
      {!inScreen && <TreeBorder/>}
        <motion.div onClick={() => scrollToTop()} onAnimationStart={() => setIsAnimating(true)} onAnimationComplete={() => setIsAnimating(false)} initial={{y: 187.5, scale: 2.3, opacity:0}} {...!inScreen ? animation1 : animation2}>
          <Tree className="navbar__tree-icon"/>
        </motion.div>
      </motion.div>
      <div className="navbar__bottom"></div>
    </div>
  )
}

export default Navbar;
import react, {useState,useEffect} from "react";
import "./ContactScreen.css"
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";
import { ReactComponent as Icon } from "../images/github-icon.svg"
import Input from "../components/Input.jsx"
import SendEmail from "../functions/Email";

export default function ContactScreen() {
  const {ref, inView} = useInView({threshold: 0.5});
  const [view, setView] = useState();
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    setView(inView);
  },[inView])

  const animation1 = {
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2, 
        delay:0,
        duration: 3
      }
    }
  };

  const animation2 = {
    animate: {
      scale: 0,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!event?.target[0]?.value || !event?.target[1]?.value || !validateEmail(event?.target[0]?.value))  {
      return;
    }
    if(SendEmail(event?.target[0]?.value,event?.target[1]?.value))  {
      setSubmit(true);
    }
  }
  //#A20F2B

  const Componet = () => {
    return (
      <div className="contactScreen__component">
        <h1 className="contactScreen__h1">&#8205; KONTAKT &#8205;</h1>
        <div className="contactScreen__component-box"> 
        <Form/>
        </div> 
      </div>
    )
  } 

  const Form = () => {
    return (
      <div className="contactScreen__form">
        <Input submit={submit} placeholderSubject={"Email"} placeholderText={"Text..."} placeholderSubmit={"Odeslat"} onSubmit={handleSubmit}/>
      </div>
    )
  }

  return (
    <div className="contactScreen__container noselect">
      <motion.div ref={ref} className="contactScreen__header" >
        <motion.div initial={{scale: 0, opacity:0}} {...view ? animation1 : animation2} className="contactScreen__values">
          <Componet/>
        </motion.div>
      </motion.div>
      <motion.div initial={{scale: 0, opacity:0}} {...view ? animation1 : animation2} onClick={() => window.location.replace("https://github.com/Pavel0123/")} className="contactScreen__git">
        <Icon className="contactScreen__git-icon"/>
        <p className="contactScreen__p">Github</p>
      </motion.div>
    </div>
  )
}
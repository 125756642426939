import react, {useEffect, useState} from "react";
import { ReactComponent as Tree1 } from "../images/Tree-1.svg"
import { ReactComponent as Tree2 } from "../images/Tree-2.svg"
import { ReactComponent as Tree3 } from "../images/Tree-3.svg"
import { ReactComponent as Arrow } from "../images/arrow-down.svg"
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";
import "./HomeScreen.css";

export default function HomeScreen({state = 0}) {
  const [animate, setAnimate] = useState(state);
  const [change, setChange] = useState(false);
  const [holdValue, setHoldValue] = useState(false);

  const [isAnimating, setIsAnimating] = useState(false)
  const [inScreen, setInScreen] = useState(false)
  const {ref, inView} = useInView();
  

  const animation1 = {
    animate:{
      scale: [0, 1, 1, 1],
    },
    transition:{
      duration: 4,
      times: [0, 0.7, 1, 1],
    }
  }
  const animation2 = {
    animate:{
      scale: [0, 0, 1, 1],
      marginTop: ["-160px", "-160px", "-180px", "-180px"],
    },
    transition:{
      duration: 4,
      times: [0, 0.15, 0.6, 1],
    }
  }

  const animation3 = {
    animate:{
      scale: [0, 0, 0, 1],
      marginTop: ["-140px", "-140px", "-145px", "-180px"],
    },
    transition:{
      duration: 4,
      times: [0, 0.2, 0.3, 1],
    }
  }

  const animation4 = {
    animate:{
      scale: [1, 1.05, 1],
    },
    transition:{
      duration: 6,
      times: [0, 0.5, 1],
      repeat: Infinity,
      repeatDelay: 0,
    }
  }

  const animation5 = {
    animate:{
      scale: [1, 0.93, 1],
    },
    transition:{
      duration: 4,
      times: [0, 0.5, 1],
      repeat: Infinity,
      repeatDelay: 0,
    }
  }

  const animation6 = {
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 3
      }
    }
  };

  const animation7 = {
    animate: {
      y: -100,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 3.5
      }
    }
  };

  const animation8 = {
    animate: {
      opacity: [1, 0, 0],
      transition: {
        type: "spring",
        times: [0, 0, 1],
        bounce: 0,
        duration: 0
      }
    }
  };

  const animation9 = {
    animate: {
      opacity: [0, 0, 1],
      transition: {
        type: "spring",
        times: [0, 0.5, 0.7],
        bounce: 0,
        duration: 4
      }
    }
  };

  const animation10 = {
    animate: {
      opacity: [0,1,0],
      y: [-50,0,50],
      transition: {
        times: [0,0.5,1],
        delay:2,
        duration: 3
      }
    }
  };

  const animation11 = {
    animate: {
      y: -50,
      opacity: 0,
      transition: {
        bounce: 0,
        duration: 2
      }
    }
  };

  useEffect(() => {
    if(holdValue) {
      if(!inView)  {
        setChange(true)
      }
    } else {
      setHoldValue(true)
    }
  },[inView])

  useEffect(() => {
    if(!isAnimating) {
      setInScreen(inView);
    }
  },[inView, isAnimating])

 


  const Tree = () => {
    return(
      <div className="homeScreen__tree-container">
        <motion.div initial={state === 0 ?{scale: 0} : null} {... animate === 0? animation1 : null}>
          <Tree1 className="homeScreen__tree-icon-1"/>
        </motion.div>
        <motion.div initial={state === 0 ?{scale: 0} : null} {... animate === 0 ? animation2 : animation4} className="homeScreen__tree-margin">
          <Tree2 className="homeScreen__tree-icon-2"/>
        </motion.div>
        <motion.div initial={state === 0 ?{scale: 0} : null} {... animate === 0 ? animation3 : animation5} onAnimationComplete={() => setAnimate(1)} className="homeScreen__tree-margin">
          <Tree3 className="homeScreen__tree-icon-3"/> 
        </motion.div>
    </div>
    )
  }
  return (
    <div className="homeScreen__container">
      <div className="ref" ref={ref}></div>
      <motion.div  className="homeScreen__header">
        <motion.div onAnimationStart={() => setIsAnimating(true)} onAnimationComplete={() => setIsAnimating(false)} {...change ? !inScreen ? animation8 : animation9 : null} className="homeScreen__tree-container" >
         { <Tree/>}
        </motion.div>
        <motion.div initial={{y: 100, opacity: 0}} {...animate >= 1 ? !inScreen ? animation7 : animation6 : null}>
          <h1 className="homeScreen__h1" >Pavel Suchánek</h1>
          <motion.div initial={{opacity: 0}} {...animate >= 1 ? animation6 : null} >
            <h2 className="homeScreen__h2" >Front-End Developer</h2>
          </motion.div>
        </motion.div>
        <motion.div className="homeScreen__arrow-icon" initial={{y: -50, opacity: 0}} {...animate >= 1 ? !inScreen ? animation11 : animation10 : null}>
          <Arrow style={{width: 40}} />
        </motion.div>
      </motion.div>
    </div>
  )
}
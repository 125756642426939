import react, {useEffect,useState, useRef, Component} from 'react';
import {motion} from "framer-motion"
import { useInView } from 'react-intersection-observer';
import ProjectScreen from '../screens/ProjectScreen.jsx';
import AboutScreen from '../screens/AboutScreen.jsx';
import SkillsScreen from '../screens/SkillsScreen.jsx';
import ContactScreen from "../screens/ContactScreen.jsx"

const Router = () => {
  const {ref, inView} = useInView();
  const [view, setView] = useState();
  const [viewMain, setViewMain] = useState();
  const [scroll, setScroll] = useState(false);



  const animation1 = {
    animate: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 2
      }
    }
  };

  const animation2 = {
    animate: {
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 2
      }
    }
  };
  

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setViewMain(inView)
    }, 2000)
    let timer2 = setTimeout(() => {
      setView(inView);
    }, 2000)
    if(!inView) {
      setView(false)
      setViewMain(false)
    }
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  },[inView])


  useEffect(() => {
    let timer1;
    if(!inView) {
      setScroll(false);
      timer1 = setTimeout(() => {
        setScroll(true);
      }, 2000)
    }
    return () => {
      clearTimeout(timer1);
    };
  },[inView])

  




  return(
    <div ref={ref}>
      <motion.div style={scroll ? {display: "block"} : {display: "none"}} initial={{opacity: 0} } {...viewMain ? animation1 : animation2}>
        <div className="component">
          <AboutScreen/>
        </div>
        <div  style={view ? {display: "block"} : {display: "none"}} className="component">
          <SkillsScreen/>
        </div>
        <div  style={view ? {display: "block"} : {display: "none"}} className="component">
          <ProjectScreen/>
        </div>
        <div style={view ? {display: "block"} : {display: "none"}} className="component">
          <ContactScreen/>
        </div>
      </motion.div>
    </div>
  )
}

export default Router;
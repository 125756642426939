import react, {useState,useEffect} from "react";
import "./AboutScreen.css"
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";

export default function AboutScreen() {
  const {ref, inView} = useInView({threshold: 0.5});
  const [view, setView] = useState(false);
  const [delay, setDelay] = useState(false);

  const [click, setClick] = useState(0);

  useEffect(() => {
    if(inView)  {
    setTimeout(() => {
      setDelay(true);
    }, 2000)
    }
      setView(inView)
  },[inView])

  const animation1 = {
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        bounce: 0,
        delay: 0.5,
        duration: 2
      }
    }
  };

  const animation2 = {
    animate: {
      opacity: 0,
      x: -25,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const animation3 = {
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        bounce: 0,
        delay: 0.5,
        duration: 2
      }
    }
  };

  const animation4 = {
    animate: {
      opacity: 0,
      x: -25,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const animation5 = {
    animate: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 3
      }
    }
  };

  const animation6 = {
    animate: {
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const animationclick = {
      scale: 1.05,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
    }
  };

  const animation7 = {
    animate: {
      opacity: 0.65,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0,
        delay: 1,
        duration: 4
      }
    }
  };

  const animation8 = {
    animate: {
      opacity: 0,
      y: 25,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const animationclick1 = {
    opacity:0.5,
    transition: {
      type: "spring",
      bounce: 0,
      duration: 1
  }
};



  const Componet = () => {
    return (
      <div className="aboutScreen__component noselect" >
        <h1 className="aboutScreen__h1">&#8205; KDO JSEM &#8205;</h1>
        <div className="aboutScreen__component-box"> 
          <motion.div  className="aboutScreen__component-left">
            <motion.div className="aboutScreen__component-left-box">
              <div className="aboutScreen__p-box">
                <motion.p initial={{opacity: 0 , x: -25}} {...view && delay ? animation1 : animation2} className="aboutScreen__p" whileTap={animationclick} whileHover={animationclick}>Jsem student se zaměřením na softwarový vývoj. Programuji již od svých 15 let.</motion.p>
              </div>
              <div className="aboutScreen__p-box">
                <motion.p  initial={{opacity: 0 , x: 25}} {...view && delay ? animation3 : animation4} className="aboutScreen__p" style={{marginTop:0}} whileTap={animationclick} whileHover={animationclick}>Začal jsem s menšími projekty, a nyní se věnuji vývoji webových aplikací.</motion.p>
              </div>
            </motion.div >
          </motion.div>
        </div> 
        <motion.div whileTap={animationclick} whileHover={animationclick} className="aboutScreen__component-bottom" initial={{opacity: 0 , y: 25}} {...view && delay ? animation7 : animation8}></motion.div>
      </div>
    )
  } 

  return (
    <motion.div className="aboutScreen__container">
      <motion.div ref={ref} className="aboutScreen__header" >
        <motion.div initial={{opacity:0}} {...view ? animation5 : animation6} className="aboutScreen__values">
          <Componet/>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}
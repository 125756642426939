import './App.css';
import React,{useState, useEffect} from 'react';
import {
  motion,
  useViewportScroll,
  useTransform
} from "framer-motion";
import { useInView } from 'react-intersection-observer';
import HomeScreen from './screens/HomeScreen.jsx';
import AboutScreen from './screens/AboutScreen.jsx';
import ContactScreen from "./screens/ContactScreen.jsx"
import Router from "./components/Router.jsx"
import Navbar from './components/Navbar';


function App() {
  const [delay, setDelay] = useState(false)
  const [state, setState] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setDelay(true)
      setState(1)
    }, 4000)
  })




  const View = () => {
    return(
      <div>
        <div id="App-Main" className="component" >
          <HomeScreen state={state}/>
        </div>
        <div style={delay ? {display: "block"} : {display: "none"}}>
          <Router/>
        </div>
      </div>
    )
  }

  return (
    <div id="App" className="App">
      <Navbar/>
      <View/>
    </div>
  );
}

export default App;

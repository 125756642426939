import React,{ useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./Input.css"

const Input = ({ onSubmit, placeholderSubject, placeholderText, placeholderSubmit , submit}) => {
  const [subject, setSubject] = useState("");
  const [value, setValue] = useState("");
  const [placeholder, setPlaceholder] = useState(placeholderSubmit);


  const animation1 = {
    animate: {
      opacity: [1, 0, 1],
      transition: {
        type: "spring",
        bounce: 0,
        delay: 0,
        times: [0,0.5,1],
        duration: 2
      }
    }
  };

  useEffect(() => {
    if(submit)  {
      setTimeout(() => {
        setPlaceholder("Dáme vám brzy vědět")
      },1000)
    }
  }, [submit])

  useEffect(() => {
    if(value || subject)  {
      setPlaceholder("Odeslat")
    }
  }, [value,subject])

  return(
      <form onSubmit={onSubmit}>
          <input onChange={(e) => setSubject(e.target.value)} className="Input__container" value={subject} placeholder={placeholderSubject} type="text" />
          <div className="Input__box">
          <textarea onChange={(e) => setValue(e.target.value)} className="Input__container-big" value={value} placeholder={placeholderText} type="text" />
          </div>
          <motion.input {...submit ? animation1 : null} type="submit" value={placeholder} className="Input__submit"/>
      </form>
  )
}

export default Input;

